import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ToastPlugin from 'vue-toast-notification';

import 'vue-toast-notification/dist/theme-bootstrap.css';

import VueCropper from 'gpower-cropper'; 
import 'gpower-cropper/dist/index.css'

import { vMaska } from "maska/vue"

import VueBottomSheet from "@dmitryarkh/vue-bottom-sheet";
import "@dmitryarkh/vue-bottom-sheet/dist/style.css";

import VueApexCharts from 'vue3-apexcharts';

// Vue uygulamasını oluştur
const app = createApp(App);

// Eklentileri kullan
app.use(router);
app.use(store);
app.use(VueCropper);
app.use(ToastPlugin);
app.directive("maska", vMaska)

// Global bileşen olarak apexchart'ı ekle
app.component('apexchart', VueApexCharts);
app.component('VueBottomSheet',VueBottomSheet)

// Uygulamayı mount et
app.mount('#app');

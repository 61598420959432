<template>
  <div class="row">
    <div class="col-lg-5 col-xl-4">
      <div
        class="card card-profile cover-image"
        data-bs-image-src="assets/images/photos/8.jpg"
      >
        <div class="card-body text-center">
          <img
            class="card-profile-img"
            :src="'https://storage.terapivitrini.com/' + data.profile_image_url"
            alt="img"
          />
          <h3 class="mb-1 text-black">{{data.fullname}}</h3>
          <p class="mb-2 text-black">{{data.expert}}</p>
          <router-link
            :to="'/user/myprofile/edit'"
            class="btn btn-success btn-sm mt-2"
            ><i class="fa fa-pencil" aria-hidden="true"></i> Profili
            Güncelle</router-link
          >
        </div>
      </div>
      <div class="card p-5">
        <div class="card-title">İletişim &amp; Kişisel Bilgiler</div>
        <div class="media-list">
          <div class="media mt-1 pb-2">
            <div class="mediaicon">
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
            </div>
            <div class="card-body p-1 ms-5">
              <h6 class="mediafont text-dark">E-Mail</h6>
              <span class="d-block">{{data.email}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-7 col-xl-8">
      <div class="card">
        <div class="card-body">
          <div id="profile-log-switch">
            <div class="fade show active">
              <div class="table-responsive border">
                <table class="table row table-borderless w-100 m-0">
                  <tbody class="col-lg-12 col-xl-6 p-0">
                    <tr>
                      <td><strong>Tam Adınız :</strong> {{data.fullname}}</td>
                    </tr>
                  </tbody>
                  <tbody class="col-lg-12 col-xl-6 p-0">
                    <tr>
                      <td>
                        <strong>Email :</strong> {{data.email}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MyProfile",
  components: {},
  created() {
    document.title = "Profilim";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Profilim";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({ url: "", name: "Profilim" });
    this.$store.dispatch("profileGet").then((value) => {
      this.data = value[0];
      this.$store.state.bread_crumb_title = "Kullanıcı Güncelle";
      this.$store.state.bread_crumb_items = [];
      this.$store.state.bread_crumb_items.push({
        url: "/dashboard",
        name: "Gösterge Paneli",
      });
      this.$store.state.bread_crumb_items.push({
        url: "/user/list",
        name: "Kullanıcı Listesi",
      });
      this.$store.state.bread_crumb_items.push({
        url: "",
        name: value[0].email,
      });
    });
  },

  data() {
    return {
      data: [],
    };
  },
  mounted() {},
};
</script>
<template>
    <div class="row">
      <div class="col-md-12">
        <div class="card m-b-20">
          <div class="card-header">
            <h3 class="card-title">Merkez İnceleme</h3>
          </div>
          <div class="card-body">
            <div
              class="alert alert-warning w-100"
              role="alert"
              v-if="data.type == 'EXPERT' && data.approved_profile == 0"
            >
              Profil için henüz işlem yapılmadı
            </div>
            <div
              class="alert alert-warning w-100"
              role="alert"
              v-if="data.type == 'EXPERT' && data.approved_profile == 2"
            >
              Profil Onay Bekliyor
            </div>
            <div
              class="alert alert-danger w-100"
              role="alert"
              v-if="data.type == 'EXPERT' && data.approved_profile == 3"
            >
              Profil Reddedildi
            </div>
            <div
              class="alert alert-success w-100"
              role="alert"
              v-if="data.type == 'EXPERT' && data.approved_profile == 1"
            >
              Profil Onaylandı
            </div>
            <hr />
            <div class="form-label">
              <h3>İşlem Gerçekleştir</h3>
            </div>
  
            <div class="row">
              <div class="col-12">
                <li
                  v-for="(item, i) in data.decline_list"
                  :key="'decline-' + i"
                  class="text-danger"
                >
                  Red Sebebi:{{ item.content }} Tarihi:{{ item.created_at }}
                </li>
              </div>
              <div class="col-12">
                <textarea
                  class="form-control"
                  placeholder="Açıklama Girin"
                  name="description"
                ></textarea>
                <div class="input-group">
                  <button
                    class="btn btn-primary w-100 mt-3 mb-3 col-6"
                    @click="process(1)"
                    id="saveButton"
                  >
                    Onayla
                  </button>
                  <button
                    class="btn btn-danger w-100 mt-3 mb-3 col-6"
                    @click="process(3)"
                    id="saveButton"
                  >
                    Reddet
                  </button>
                </div>
              </div>
              <hr />
              <div class="form-label">
                <h3>Merkez Bilgileri</h3>
              </div>
              <div class="col-6">
                <div class="form-label">Profile Ziyaret:</div>
                {{ data.profile_view_count }}
              </div>
              <hr />
              <div class="col-4">
              <div class="form-label">Profil Resmi:</div>
              <div class="profile-pic">
                <div class="profile-pic-img">
                  <span
                    class="dots"
                    @click="profileImageUpload"
                    style="
                      background: #d7d7d7;
                      cursor: pointer;
                      font-size: initial;
                      width: 30px;
                      height: 30px;
                    "
                    ><i class="side-menu__icon si si-pencil"></i
                  ></span>
                  <img
                    style="border-radius: 50px"
                    width="100"
                    :src="
                      'https://storage.terapivitrini.com/' +
                      data.profile_image_url
                    "
                  />
                </div>
              </div>
              <vue-bottom-sheet max-width="95%" ref="profileImageUpload">
                <div class="card mb-0">
                  <div class="card-header">
                    <h3 class="card-title">Profile Resmi</h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <!-- Dosya yükleme ve kırpma alanı -->
                      <input type="file" @change="onFileChange" accept="image/*" />
                      <cropper ref="croppers" :src="profileCroppa" :stencil-size="{
                        width: 250,
                        height: 250
                      }" @change="updateCroppedImage" />
                        
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="button"
                      @click="fileUpload"
                      id="saveProfileButton"
                      class="btn btn-primary"
                    >
                      Resmi Yükle
                    </button>
                  </div>
                </div>
              </vue-bottom-sheet>
            </div>
              <div class="col-4">
                <div class="form-label">Merkez Adı:</div>
                <input type="text" class="form-control" v-model="data.fullname" />
              </div>
  
              <div class="col-4">
                <div class="form-label">Yorum:</div>
                {{ data.total_comment }}
              </div>
              <div class="col-4">
                <div class="form-label">Puan:</div>
                {{ Math.ceil(data.total_score / 5) }}
              </div>
              <hr />
              <div class="col-12">
                <div class="form-label">Hakkında:</div>
                <div id="content" v-html="data.about"></div>
              </div>
              <hr />
              <div class="col-12">
                <div class="form-label">Videolar:</div>
                <div class="row">
                  <div
                    class="col-3"
                    v-for="(item, i) in data.videos"
                    :key="'videos' + i"
                  >
                    <iframe
                      height="200"
                      :src="
                        'https://www.youtube.com/embed/' +
                        item.path.split('?v=')[1]
                      "
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <hr />
              <div class="col-12">
                <div class="form-label">Resimler:</div>
                <div class="row">
                  <div
                    class="col-3"
                    v-for="(item, i) in data.images"
                    :key="'images' + i"
                  >
                    <img
                      :src="'https://storage.terapivitrini.com/' + item.image_url"
                      alt="img"
                      width="200"
                      class="cover-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-primary" @click="save">Kaydet</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
  import Swal from "sweetalert2";
  import "sweetalert2/src/sweetalert2.scss";
  export default {
    setup() {
    const croppers = ref();
    return {
      croppers
    };
  },
    name: "UserUpdate",
    components: {
    Cropper,
  },
    created() {
      document.title = "Merkez İnceleme";
      this.$store.state.header = true;
      this.get();
    },
    data() {
      return {
        data: [],
        generatePassword: "",
        profileCroppa: null,
      croppedImage: null,
      };
    },
    methods: {
      onFileChange() {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.profileCroppa = e.target.result; // Görseli yükle
        };
        reader.readAsDataURL(file);
      }
    },
    updateCroppedImage() {

      if (this.$refs.croppers) {
        const croppedCanvas = this.$refs.croppers.getResult({
          type: 'base64',
        });
        this.croppedImage = croppedCanvas.canvas.toDataURL();
      }
    },
      profileImageUpload() {
      this.$refs.profileImageUpload.open();
    },
    fileUpload() {
      document.getElementById("saveProfileButton").innerText =
        "Dosya Yükleniyor...";
      document.getElementById("saveProfileButton").disabled = true;
      
      let file = this.dataURLtoFile(this.croppedImage, this.data.shortlink + ".jpg");
      let formData = new FormData();
      formData.append("file", file);
      formData.append("id", this.data.user_id);
      this.$store.dispatch("memberProfileUpload", formData).then((response) => {
        this.$toast.warning("Resim Başarılı bir şekilde yüklendi", {timeout: 2000});
        this.data.profile_image_url = response.profile_image;
        document.getElementById("saveProfileButton").innerText = "Resmi Yükle";
        document.getElementById("saveProfileButton").disabled = false;
        this.$refs.profileImageUpload.close();
      });
    },
      get() {
        this.$store
          .dispatch("memberGet", { id: this.$route.params.id })
          .then((value) => {
            if (value.length == 0) {
              this.$router.push("/member/list");
            } else {
              this.data = value[0];
              $("#content").summernote("code", this.data.about);
              this.$store.state.bread_crumb_title = "Merkez İnceleme";
              this.$store.state.bread_crumb_items = [];
              this.$store.state.bread_crumb_items.push({
                url: "/dashboard",
                name: "Gösterge Paneli",
              });
              this.$store.state.bread_crumb_items.push({
                url: "/member/list",
                name: "Üye Listesi",
              });
              this.$store.state.bread_crumb_items.push({
                url: "",
                name: value[0].email,
              });
            }
          });
      },
      dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
      save() {
      this.data.about = $("#content").summernote("code");
      this.$store
        .dispatch("memberSave", {
          id: this.$route.params.id,
          data: JSON.stringify(this.data),
        })
        .then((value) => {
          this.$toast.warning("Kayıt başarılı", {
            timeout: 1000,
          });
        });
    },
      process(type) {
        /** MEMBER SAVE */
  
        let description = document.querySelector(
          "textarea[name=description]"
        ).value;
        if (description == "" && type == 3) {
          this.$toast.warning("Lütfen reddedilme açıklaması girin", {
            timeout: 1000,
          });
        } else {
          if (type == 1) {
            Swal.fire({
              title: "Merkez profili onaylanacak",
              text: "Onay işlemini gerçekleştirmek istediğinize emin misiniz?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Onayla",
              cancelButtonText: "İptal",
            }).then((result) => {
              if (result.isConfirmed) {
                /**ONAYLMA İŞLEMİ BAŞLAT */
                let userInfo = {
                  type: type,
                  description: "",
                  id: this.$route.params.id,
                };
                this.$store
                  .dispatch("memberExpertApproved", userInfo)
                  .then((value) => {
                    if (value.type == "success") {
                      this.$toast.warning("İşlem Başarılı", {
                        timeout: 2000,
                      });
                      this.get();
                    } else if (value.type == "error") {
                      /**HATALI İŞLEM VARSA */
                    }
                  });
              }
            });
          } else if (type == 3) {
            Swal.fire({
              title: "Merkez profili reddedilecek",
              text: "Merkez profili reddetme işlemini tamamlamak istediğinize emin misiniz?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Onayla",
              cancelButtonText: "İptal",
            }).then((result) => {
              if (result.isConfirmed) {
                /**İPTAL İŞLEMİ BAŞLAT */
                let userInfo = {
                  type: type,
                  description: description,
                  id: this.$route.params.id,
                };
                this.$store
                  .dispatch("memberExpertApproved", userInfo)
                  .then((value) => {
                    if (value.type == "success") {
                      this.$toast.warning("İşlem Başarılı", {
                        timeout: 2000,
                      });
                      this.get();
                      document.querySelector("textarea[name=description]").value="";
                    } else if (value.type == "error") {
                      /**HATALI İŞLEM VARSA */
                    }
                  });
              }
            });
          }
        }
      },
    },
    mounted() {
    $(document).ready(function () {
      $("#content").summernote({
        toolbar: [
          [
            "style",
            ["highlight", "bold", "italic", "underline", "clear", "style"],
          ],
          ["font", ["strikethrough", "superscript", "subscript", "fontname"]],
          ["color", ["color"]],
          ["table", ["table"]],
          ["para", ["ul", "ol", "paragraph", "hr"]],
          ["insert", ["tableofcontent"]],
          ["link", ["linkDialogShow", "unlink"]],
          ["view", ["fullscreen", "codeview", "help"]],
          ["fontsize", ["fontsize"]],
          ["height", ["height"]],
        ],
      });
    });
  },
  };
  </script>
<template>
  <aside class="app-sidebar doc-sidebar">
    <div class="app-sidebar__user clearfix">
      <div class="dropdown user-pro-body">
        <div>
          <img
            :src="
              'https://storage.terapivitrini.com/' + $store.state.profile_image
            "
            alt="user-img"
            class="avatar avatar-lg brround"
          />
          <router-link to="/user/myprofile" class="profile-img">
            <span class="fa fa-pencil" aria-hidden="true"></span>
          </router-link>
        </div>
        <div class="user-info">
          <h2>{{ $store.state.fullname }}</h2>
          <span>Supervisor</span>
        </div>
      </div>
    </div>
    <ul class="side-menu">
      <!--GÖSTERGE PANELİ-->
      <li class="slide" v-if="authFind(24) == 1">
        <router-link class="side-menu__item slide-show" :to="'/'"
          ><i class="side-menu__icon fe fe-airplay"></i
          ><span class="side-menu__label">Gösterge Paneli</span>
        </router-link>
      </li>
      <!--MAKALE İŞLEMLERİ-->
      <li class="slide" v-if="authFind(1) == 1">
        <a class="side-menu__item slide-show" href="#"
          ><i class="side-menu__icon fe fe-layers"></i
          ><span class="side-menu__label">Makale İşlemleri</span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li>
            <router-link class="slide-item" :to="'/blog/create'"
              >Yeni Makale</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/category/create'"
              >Yeni Kategori</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/blog/list'"
              >Makale Listesi</router-link
            >
          </li>

          <li>
            <router-link class="slide-item" :to="'/category/list'"
              >Kategori List</router-link
            >
          </li>
        </ul>
      </li>
      <!--REKLAM İŞLEMLERİ-->
      <li class="slide" v-if="authFind(2) == 1">
        <a class="side-menu__item slide-show" href="#"
          ><i class="side-menu__icon fe fe-tag"></i
          ><span class="side-menu__label">Reklam İşlemleri</span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li>
            <router-link class="slide-item" :to="'/ads/create'"
              >Yeni Reklam</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/ads/list'"
              >Reklam Listesi</router-link
            >
          </li>
        </ul>
      </li>
      <!--TEST İŞLEMLERİ-->
      <li class="slide" v-if="authFind(3) == 1">
        <a class="side-menu__item slide-show" href="#"
          ><i class="side-menu__icon fe fe-tag"></i
          ><span class="side-menu__label">Test İşlemleri</span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li>
            <router-link class="slide-item" :to="'/survey/create'"
              >Yeni Test</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/survey/list'"
              >Test Listesi</router-link
            >
          </li>
        </ul>
      </li>
      <!--MENU İŞLEMLERİ-->
      <li class="slide" v-if="authFind(4) == 1">
        <router-link class="side-menu__item slide-show" to="/menu/list"
          ><i class="side-menu__icon fe fe-aperture"></i
          ><span class="side-menu__label">Menü Listesi</span></router-link
        >
      </li>
      <!--EĞİTİM İŞLEMLERİ-->
      <li class="slide" v-if="authFind(5) == 1">
        <a class="side-menu__item slide-show" href="#"
          ><i class="side-menu__icon fe fe-file-text"></i
          ><span class="side-menu__label">Eğitim İşlemleri</span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li>
            <router-link class="slide-item" to="/member/expert/education/list"
              >Eğitim Listesi</router-link
            >
          </li>
          <li>
            <router-link
              class="slide-item"
              :to="'/member/expert/education/waiting/pay'"
              >Ödeme Bekleyen Eğitimler</router-link
            >
          </li>
        </ul>
      </li>
      <!--SAYFA İŞLEMLERİ-->
      <li class="slide" v-if="authFind(6) == 1">
        <a class="side-menu__item slide-show" href="#"
          ><i class="side-menu__icon fe fe-layers"></i
          ><span class="side-menu__label">Sayfa İşlemleri</span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li>
            <router-link class="slide-item" :to="'/page/create'"
              >Yeni Sayfa</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/page/list'"
              >Sayfa Listesi</router-link
            >
          </li>
        </ul>
      </li>
      <!--HİZMET İŞLEMLERİ-->
      <li class="slide" v-if="authFind(7) == 1">
        <a class="side-menu__item slide-show" href="#"
          ><i class="side-menu__icon fe fe-file-text"></i
          ><span class="side-menu__label">Hizmet İşlemleri</span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li>
            <router-link class="slide-item" :to="'/service/create'"
              >Yeni Hizmet Ekle</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/service/list'"
              >Hizmet Listesi</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/service/request/list'"
              >Hizmet Talepleri</router-link
            >
          </li>
        </ul>
      </li>
      <!--YORUM HİZMETLERİ-->
      <li class="slide" v-if="authFind(8) == 1">
        <a class="side-menu__item slide-show" href="#"
          ><i class="side-menu__icon fe fe-shopping-bag"></i
          ><span class="side-menu__label">Yorum İşlemleri</span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li>
            <router-link class="slide-item" :to="'/comment/blog/list'"
              >Blog Yorumları</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/comment/profile/list'"
              >Profil Yorumları</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/comment/education/list'"
              >Eğitim Yorumları</router-link
            >
          </li>
        </ul>
      </li>
      <!--GÖREV İŞLEMLERİ-->
      <li class="slide" v-if="authFind(9) == 1">
        <a class="side-menu__item slide-show" href="#"
          ><i class="side-menu__icon fe fe-tag"></i
          ><span class="side-menu__label">Görev İşlemleri</span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li>
            <router-link class="slide-item" :to="'/job/create'"
              >Yeni Görev</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/job/list'"
              >Görev Listesi</router-link
            >
          </li>
        </ul>
      </li>
      <!--MOBİL İŞLEMLERİ-->
      <li class="slide" v-if="authFind(10) == 1">
        <a class="side-menu__item slide-show" href="#"
          ><i class="side-menu__icon fe fe-pie-chart"></i
          ><span class="side-menu__label">Mobil İşlemleri</span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li>
            <router-link class="slide-item" :to="'/mobile/slider/list'"
              >Slider Listesi</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/mobile/slider/survey/list'"
              >Test Slider Listesi</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/mobile/search/list'"
              >Arama Nesneleri</router-link
            >
          </li>
        </ul>
      </li>
      <!--RANDEVU İŞLEMLERİ-->
      <li class="slide" v-if="authFind(11) == 1">
        <router-link class="side-menu__item slide-show" :to="'/member/expert/preappointment/list'"
          ><i class="side-menu__icon fe fe-pie-chart"></i
          ><span class="side-menu__label">Randevu Listesi</span
          ></router-link>
        
      </li>
      <!--ÜYE İŞLEMLERİ-->
      <li class="slide" v-if="authFind(12) == 1">
        <a class="side-menu__item slide-show" href="#"
          ><i class="side-menu__icon fe fe-pie-chart"></i
          ><span class="side-menu__label">Üye Listesi</span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li>
            <router-link class="slide-item" :to="'/member/create'"
              >Yeni Üye Ekle</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/member/list'"
              >Üye Listesi</router-link
            >
          </li>

          <li>
            <router-link class="slide-item" :to="'/member/expert/list'"
              >Uzman Listesi</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/center/list'"
              >Merkez Listesi</router-link
            >
          </li>
        </ul>
      </li>
      <!--FATURA İŞLEMLERİ-->
      <li class="slide" v-if="authFind(13) == 1">
        <a class="side-menu__item slide-show" href="#"
          ><i class="side-menu__icon fa fa-credit-card"></i
          ><span class="side-menu__label">Fatura İşlemleri</span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li>
            <router-link class="slide-item" :to="'/billing/list'"
              >Fatura Listesi</router-link
            >
          </li>
          <li>
            <router-link
              class="slide-item"
              :to="'/member/expert/billing_chage/request'"
              >Uzman Faturalandırma Değişiklik Talepleri</router-link
            >
          </li>
        </ul>
      </li>
      <!--İSTATİSTİKLER-->
      <li v-if="authFind(14) == 1">
        <a class="side-menu__item"
          ><i class="side-menu__icon fe fe-map"></i
          ><span class="side-menu__label">İstatistikler</span></a
        >
      </li>
      <!--AYARLAR-->
      <li class="slide" v-if="authFind(15) == 1">
        <a class="side-menu__item slide-show" href="#"
          ><i class="side-menu__icon fe fe-shopping-bag"></i
          ><span class="side-menu__label">Ayarlar</span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li>
            <router-link class="slide-item" :to="'/setting'"
              ><span class="side-menu__label"> Site Ayarları</span></router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/sms-texts'"
              ><span class="side-menu__label"> Sms Metinleri</span></router-link
            >
          </li>
          <li v-if="authFind(17) == 1">
            <router-link class="slide-item" :to="'/faq'"
              ><span class="side-menu__label">
                S.S.S. Listesi</span
              ></router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/user/create'"
              >Yeni Kullanıcı Ekle</router-link
            >
          </li>
          <li v-if="authFind(18) == 1">
            <router-link class="slide-item" :to="'/user/list'"
              >Kullanıcı Listesi</router-link
            >
          </li>
        </ul>
      </li>
      <!--MEDYA İŞLEMLERİ-->
      <li class="slide" v-if="authFind(16) == 1">
        <a class="side-menu__item slide-show" href="#"
          ><i class="side-menu__icon fe fe-layout"></i
          ><span class="side-menu__label">Medya İşlemleri</span
          ><i class="angle fa fa-angle-right"></i
        ></a>
        <ul class="slide-menu">
          <li>
            <router-link class="slide-item" :to="'/media/create'"
              >Yeni Medya Ekle</router-link
            >
          </li>
          <li>
            <router-link class="slide-item" :to="'/media/list'"
              >Medya Listesi</router-link
            >
          </li>
        </ul>
      </li>
      <!--ZİYARETÇİ-->
      <li class="slide" v-if="authFind(19) == 1">
        <router-link class="side-menu__item slide-show" :to="'/visitor/list'"
          ><i class="side-menu__icon fe fe-tag"></i
          ><span class="side-menu__label">Ziyaretçi İşlemleri</span
          ></router-link>
      
      </li>
    </ul>
  </aside>
</template>
<script>
export default {
  methods: {
    authFind(id) {
      if (this.$store.state.auth.length != 0) {
        return this.$store.state.auth[id].status;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      (function () {
        "use strict";
        var slideMenu = $(".side-menu");
        // Toggle Sidebar
        $('[data-bs-toggle="sidebar"]').click(function (event) {
          event.preventDefault();
          $(".app").toggleClass("sidenav-toggled");
        });
        $(window).on("load resize", function () {
          if ($(window).width() < 739) {
            $(".app-sidebar").hover(function (event) {
              event.preventDefault();
              $(".app").addClass("sidenav-toggled");
            });
          }
          if ($(window).width() > 739) {
            $(".app-sidebar").hover(function (event) {
              event.preventDefault();
              $(".app").removeClass("sidenav-toggled");
            });
          }
        });
        // Set initial active toggle
        $(".sub-slide-show.is-expanded").parent().toggleClass("is-expanded");
        $(".child-sub-menu.open")
          .parent()
          .parent()
          .parent()
          .parent()
          .parent()
          .toggleClass("is-expanded");

        // Activate sidebar slide toggle
        $(".slide-show").on("click", function (e) {
          var $this = $(this);
          var checkElement = $this.next();
          var animationSpeed = 300,
            slideMenuSelector = ".slide-menu";
          if (
            checkElement.is(slideMenuSelector) &&
            checkElement.is(":visible")
          ) {
            checkElement.slideUp(animationSpeed, function () {
              checkElement.removeClass("open");
            });
            checkElement.parent("li").removeClass("is-expanded");
          } else if (
            checkElement.is(slideMenuSelector) &&
            !checkElement.is(":visible")
          ) {
            var parent = $this.parents("ul").first();
            var ul = parent.find("ul:visible").slideUp(animationSpeed);
            ul.removeClass("open");
            var parent_li = $this.parent("li");
            checkElement.slideDown(animationSpeed, function () {
              checkElement.addClass("open");
              parent.find("li.is-expanded").removeClass("is-expanded");
              parent_li.addClass("is-expanded");
            });
          }
          if (checkElement.is(slideMenuSelector)) {
            e.preventDefault();
          }
        });

        // Activate sidebar slide toggle
        $(".sub-slide-show").on("click", function (e) {
          var $this = $(this);
          var checkElement = $this.next();
          var animationSpeed = 300,
            slideMenuSelector = ".child-sub-menu";
          if (
            checkElement.is(slideMenuSelector) &&
            checkElement.is(":visible")
          ) {
            checkElement.slideUp(animationSpeed, function () {
              checkElement.removeClass("open");
            });
            checkElement.parent("li").removeClass("is-expanded");
          } else if (
            checkElement.is(slideMenuSelector) &&
            !checkElement.is(":visible")
          ) {
            var parent = $this.parents("ul").first();
            var ul = parent.find("ul:visible").slideUp(animationSpeed);
            ul.removeClass("open");
            var parent_li = $this.parent("li");
            checkElement.slideDown(animationSpeed, function () {
              checkElement.addClass("open");
              parent.find("li.is-expanded").removeClass("is-expanded");
              parent_li.addClass("is-expanded");
            });
          }
          if (checkElement.is(slideMenuSelector)) {
            e.preventDefault();
          }
        });

        // Activate sidebar slide toggle
        $(".sub-slide2-show").on("click", function (e) {
          var $this = $(this);
          var checkElement = $this.next();
          var animationSpeed = 300,
            slideMenuSelector = ".child-sub-menu2";
          if (
            checkElement.is(slideMenuSelector) &&
            checkElement.is(":visible")
          ) {
            checkElement.slideUp(animationSpeed, function () {
              checkElement.removeClass("open");
            });
            checkElement.parent("li").removeClass("is-expanded");
          } else if (
            checkElement.is(slideMenuSelector) &&
            !checkElement.is(":visible")
          ) {
            var parent = $this.parents("ul").first();
            var ul = parent.find("ul:visible").slideUp(animationSpeed);
            ul.removeClass("open");
            var parent_li = $this.parent("li");
            checkElement.slideDown(animationSpeed, function () {
              checkElement.addClass("open");
              parent.find("li.is-expanded").removeClass("is-expanded");
              parent_li.addClass("is-expanded");
            });
          }
          if (checkElement.is(slideMenuSelector)) {
            e.preventDefault();
          }
        });

        // ______________Active Class
        $(document).ready(function () {
          $(".app-sidebar a").each(function () {
            var pageUrl = window.location.href.split(/[?#]/)[0];
            if (this.href == pageUrl) {
            }
          });
        });
      })();
    }, 2500);
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card m-b-20">
        <div class="card-header">
          <h3 class="card-title">Yeni Hizmet Ekle</h3>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="serviceName">Hizmet Adı</label>
                <input
                  type="text"
                  class="form-control"
                  id="serviceName"
                  @keyup="shortLinkGenerator"
                  name="serviceName"
                  placeholder="Hizmet Başlığı"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="shortName">URL</label>
                <input
                  type="text"
                  class="form-control"
                  id="shortName"
                  name="shortName"
                  placeholder="URL"
                  disabled
                />
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="serviceDescription"
                  >Açıklama</label
                >
                <div
                  type="text"
                  class="form-control"
                  id="serviceDescription"
                  name="serviceDescription"
                  placeholder="Hizmet Açıklama"
                  autocomplete="off"
                ></div>
              </div>
            </div>
            <div class="form-group col-md-12">
              <div class="form-group">
                <label class="form-label" for="serviceDescription"
                  >Kısa Açıklama</label
                >
                <textarea class="form-control" name="description" ></textarea>
              </div>
            </div>
            <TagListComponent :tags="tags" @tagList="tagL($event)" />
          </div>
          <vue-bottom-sheet max-width="95%" ref="myCoverImageBottomSheet">
            <CoverImageComponent
              @input="updateCoverValue($event)"
            /> </vue-bottom-sheet
          >
          <img
            v-if="imagelink != ''"
            :src="'https://storage.terapivitrini.com/' + imagelink"
          />
          <button class="btn btn-primary w-100" @click="openCoverBottom">
            Kapak Resmi
          </button>
          <div class="form-row">
            <FindInSelectComponent
              @response="handleData($event)"
              title="Bağlı Hizmet"
              :placeholder="title"
              action="serviceList"
              selected=""
              showItem="experience_name"
              valueItem="id"
              searchItem="experience_name"
            />
          </div>
          <button class="btn btn-primary" id="saveButton" @click="save">
            Kaydet
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CoverImageComponent from "../elementComponents/CoverImageComponent.vue";
import FindInSelectComponent from "../elementComponents/FindInSelectComponent.vue";
import TagListComponent from "../elementComponents/TagListComponent.vue";
export default {
  name: "ServiceAdd",
  components: {
    FindInSelectComponent,
    TagListComponent,
    CoverImageComponent
  },
  created() {
    document.title = "Yeni Hizmet Ekle";
    this.$store.state.header = true;
    this.$store.state.bread_crumb_title = "Yeni Hizmet";
    this.$store.state.bread_crumb_items = [];
    this.$store.state.bread_crumb_items.push({
      url: "/dashboard",
      name: "Gösterge Paneli",
    });
    this.$store.state.bread_crumb_items.push({
      url: "",
      name: "Yeni Hizmet Ekle",
    });
  },
  data() {
    return {
      data: [],
      subService: 0,
      title: "Üst Hizmet Seçin",
      tags: "",
      imagelink: "",
    };
  },
  methods: {
    openCoverBottom() {
      this.$refs.myCoverImageBottomSheet.open();
    },
    updateCoverValue(item) {
      if(item.target==null){
        this.imagelink = item;
        this.$refs.myCoverImageBottomSheet.close();
      }
    },
    handleData: function (e) {
      this.subService = e[0];
      this.title = e[1];
    },
    shortLinkGenerator() {
      let blogName = document.querySelector("input[name=serviceName]").value;
      blogName = blogName.toLowerCase();
      document.querySelector("input[name=shortName]").value = blogName
        .replaceAll("ğ", "g")
        .replaceAll("ü", "u")
        .replaceAll("ş", "s")
        .replaceAll("ı", "i")
        .replaceAll("ö", "o")
        .replaceAll("ç", "c")
        .replaceAll(" ", "-");
    },
    tagL: function (e) {
      this.tags = e;
    },
    save() {
      /** SERVİCE SAVE */
      document.getElementById("saveButton").disabled = true;
      document.getElementById("saveButton").innerHTML = "Kaydediliyor...";
      let serviceName = document.querySelector("input[name=serviceName]").value;
      let shortName = document.querySelector("input[name=shortName]").value;
      let serviceDescription = $("#serviceDescription").summernote("code");
      let description = document.querySelector("textarea[name=description]").value;
      if (serviceName == "") {
        this.$toast.warning("Hizmet başlığı boş olamaz");
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Kaydet";
      } else {
        let serviceInfo = {
          service_name: serviceName,
          short_name: shortName,
          service_description: serviceDescription,
          description: description,
          tags: this.tags,
          imagelink: this.imagelink,
        };
        this.$store.dispatch("serviceAdd", serviceInfo).then((value) => {
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML = "Kaydet";
          this.$toast.warning("Hizmet kayıt başarılı", {
            timeout: 2000,
          });
          setTimeout(() => {
            this.$router.push("/service/update/" + value.inserted_id);
          }, 2000);
        });
      }
    },
  },
  mounted(){
    $(document).ready(function () {
        $("#serviceDescription").summernote({
          toolbar: [
            [
              "style",
              ["highlight", "bold", "italic", "underline", "clear", "style"],
            ],
            ["font", ["strikethrough", "superscript", "subscript", "fontname"]],
            ["color", ["color"]],
            ["table", ["table"]],
            ["para", ["ul", "ol", "paragraph", "hr"]],
            ["insert", ["tableofcontent"]],
            ["link", ["linkDialogShow", "unlink"]],
            ["view", ["fullscreen", "codeview", "help"]],
          ],
        });
      });
  }
};
</script>

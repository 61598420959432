<template>
  <div class="col-xl-12 col-lg-12 col-md-12">
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Randevu Bilgileri</h2>
      </div>
      <div class="card-body">
        <template v-if="$route.query.uid > 0">
          <DateCalendar :userid="$route.query.uid" :selectedDate="$route.query.date"
            @response="handleSelectedDate($event)" />
        </template>
        <div class="form-group">
          <label class="form-label text-dark">Hizmet Seçin *</label>
          <select v-model="selectedExperiences" class="form-control" value="Hizmet Seçin">
            <option selected disabled>Hizmet Seçin</option>
            <option v-for="(a, i) in experiences" :key="'experience' + i" :value="a">
              {{ a.experience_name }}
            </option>
          </select>
        </div>
        <div class="form-group" v-if="this.selectedExperiences.length != 0">
          <label class="form-label text-dark">Görüşme Yöntemi *</label>
          <select v-model="selectedMethods" class="form-control">
            <option selected disabled>Görüşme Yöntemi Seçin</option>
            <option value="1">
              Yüz Yüze Görüşme
            </option>
            <option value="2">
              Evde Görüşme
            </option>
            <option value="3">
              Online Görüşme
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-label text-dark">Ad Soyad *</label>
          <input type="text" class="form-control" name="fullname" v-model="fullname" id="fullname" />
        </div>
        <div class="form-group">
          <label class="form-label text-dark">Telefon *</label>
          <input type="text" v-maska data-maska="0(###) ###-##-##" v-model="phone" placeholder="0(_ _ _) _ _ _-_ _-_ _"
            class="form-control" name="phone" id="phone" />
          <small>*Bu numaraya randevunuzu onaylayabilmek için bir kod
            göndereceğiz</small>
        </div>

        <div class="row" v-if="selectedExperiences != null && selectedMethods == 2">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">Adres *</label>
              <textarea type="text" class="form-control" name="address" v-model="address" placeholder="Adres"></textarea>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">İl *</label>

              <select name="city" v-model="city" id="city" class="form-control" @change="cityChange">
                <optgroup label="Şehir Seçiniz">
                  <option v-for="(item, i) in cityList" :key="i" :value="item.name"
                    :data-province="JSON.stringify(item.districts)">
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">İlçe *</label>
              <select v-model="province" name="province" id="province" class="form-control">
                <optgroup label="İlçe Seçiniz">
                  <option v-for="(item, i) in selectedDistricts" :key="i" :value="item.name">
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Açıklama</label>
          <textarea type="text" class="form-control" name="description" v-model="description"></textarea>
        </div>
        <hr />
        <button type="button" id="saveButton" class="btn btn-primary w-100" @click="save">
          <span class="indicator-label">Devam Et</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import DateCalendar from "./components/DateCalander.vue";
export default {
  created() {
    document.title = "Randevu Oluştur";

    this.dateControl(this.$route.query.uid, this.$route.query.date)
    if (this.cityList.length == 0) {
      let cityInfo = {};
      this.$store.dispatch("citysGet", cityInfo).then((value) => {
        this.cityList = value.list;
        if (this.city != null) {
          this.cityList.forEach((e) => {
            if (e.name == this.city) {
              this.selectedDistricts = e.districts;
            }
          });
        } else {
          this.selectedDistricts = value.list[0].districts;
        }
      });
    }
  },
  data() {
    return {
      user: [],
      calenderToggleBool: false,
      currentAppointmentId: 0,
      phone: "",
      currentList: [],
      cityList: [],
      selectedDistricts: [],
      checkReturn: false,
      description: "",
      selectedDate: "",
      selectedDateId: "",
      fullname: "",
      address: "",
      experiences: [],
      selected_experience_id: 0,
      currentAppointmentProgress: [],
      selectedMethods: 0,
      selectedExperiences: [],
      referanceId: 0,
      phoneRegex: new RegExp(
        "^[+]*[0]*[ ]{0}[(][5]{1}[0-9]{1,3}[ ]{0,1}[)]{0,1}[ ]{0,1}[0-9]{1,3}[ ]{0,1}[0-9]{2}[-]{0,1}[0-9]{2}[]{0,1}[-./]{0,1}[-]{0,1}[0-9]{1,5}$"
      ),
    };
  },
  methods: {
    dateControl(id, date) {

      let appointmentControl = {}
      if (this.$route.query.referanceId && this.$route.query.referanceId != null) {
        appointmentControl = {
          id: id,
          date: date,
          referanceId: this.$route.query.referanceId
        };
      } else {
        appointmentControl = {
          id: id,
          date: date,
        };
      }
      this.$store
        .dispatch("appointmentControl", appointmentControl)
        .then((value) => {
          if (value.type == "success") {
            this.user = value.user;
            this.experiences = value.experiences;
            if (this.selected_experience_id > 0) {
              this.experiences.forEach((e) => {
                if (e.experience_id == this.selected_experience_id) {
                  this.selectedExperiences = e;
                }
              });
            }
            if (value.referance_info && value.referance_info != null) {
              this.fullname = value.referance_info.fullname
              this.phone = value.referance_info.phone
              this.referanceId = value.referance_info.id
            }
            this.loading = false;
          } else {
            if (value.message == "ERRxUDF") {
              this.$toast.warning("Hatalı tarih formatı");
              this.$router.go(-1);
            }
            if (value.message == "ERRxDEX") {
              this.$toast.warning(
                "Randevu süresi geçmişten bir gün olamaz"
              );
            }
            if (value.message == "ERRxDNF") {
              this.$vToastify.warning("Randevu tarihi bulunamadı");
            }
            if (value.message == "ERRxHNF") {
              this.$vToastify.warning("Randevu tarihi bulunamadı");
            }
          }
        });

    },
    cityChange() {
      var element = document.getElementById("city");
      var option_doj =
        element.options[element.selectedIndex].getAttribute("data-province");
      this.selectedDistricts = JSON.parse(option_doj);
    },
    handleSelectedDate: function (e) {
      this.dateControl(this.$route.query.uid, e[0]);
      this.selectedDate = e[0];
      this.selectedDateId = e[1];
    },
    handleData: function (e) {
      this.checkReturn = e;
    },
    save() {
      let fullname = document.querySelector("input[name=fullname]").value;
      let address = "";
      let city = "";
      let province = "";
      if (this.selectedExperiences != null && this.selectedMethods == 2) {
        address = document.querySelector("textarea[name=address]").value;
        city = document.querySelector("select[name=city]").value;
        province = document.querySelector("select[name=province]").value;
      }
      let number = this.phone;
      if (fullname == null || fullname == "") {
        this.$toast.warning("Ad Soyad Girilmedi");
      } else if (
        this.selectedExperiences == null ||
        this.selectedExperiences.length == 0
      ) {
        this.$toast.warning("Hizmet türü seçmediniz");
      } else if (this.selectedMethods == 0) {
        this.$toast.warning("Görüşme yöntemi seçilmedi");
      } else if (number == null || number == "") {
        this.$toast.warning("Telefon numarası girilmedi");
      } else if (!number.match(this.phoneRegex)) {
        this.$toast.warning("Telefon numarası hatalı");
      } else if (
        this.selectedExperiences != null &&
        this.selectedMethods == 2 &&
        (address == null || address == "")
      ) {
        this.$toast.warning("Adres girilmedi");
      } else if (
        this.selectedExperiences != null &&
        this.selectedMethods == 2 &&
        (city == null || city == "")
      ) {
        this.$toast.warning("Şehir seçilmedi");
      } else if (
        this.selectedExperiences != null &&
        this.selectedMethods == 2 &&
        (province == null || province == "")
      ) {
        this.$toast.warning("İlçe seçilmedi");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          '<span class="indicator-progress">Lütfen bekleyiniz...<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>';
        /**BİLGİLERİ KAYDET */
        let city = "";
        let province = "";
        if (this.selectedExperiences != null && this.selectedMethods == 2) {
          city = document.querySelector("select[name=city]").value;
          province = document.querySelector("select[name=province]").value;
        }

        let appointment = {
          fullname: this.fullname,
          address: this.address,
          phone: this.phone,
          selected_experience_id: this.selectedExperiences.experience_id,
          selected_methods: this.selectedMethods,
          city: city,
          province: province,
          description: this.description,
          appointment_hour_id: this.user.appointment_hour_id,
          user_id: this.user.id,
          referance_id: this.referanceId
        };

        this.$store.dispatch("preAppointmentSave", appointment).then((value) => {

          Swal.fire("Başarılı!", "Randevu başarılı bir şekilde kaydedildi");

          this.$router.push("/member/expert/preappointment/list");
        });
      }
    },
  },
  components: { DateCalendar },
  mounted() { },
};
</script>
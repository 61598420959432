const axios = require("axios");
import router from "../router/index";
var url = "https://api.terapivitrini.com/";
let _this = this;
export default {
  /**Login Actions */
  async login(context, userInfo) {
    return axios
      .post(url + "api/auth/login", {
        email: userInfo["email"],
        password: userInfo["password"],
      })
      .then((resp) => {
        if (!resp.data.error) {
          localStorage.setItem("token", resp.data.access_token);
          context.commit("fullname", resp.data.name);
          context.commit("profile_image", resp.data.profile_image);
          context.commit("auth", resp.data.auths);
          return "success";
        } else {
          return resp.data.error;
        }
      })
      .catch((e) => {
        return resp.data.error;
      });
  },
  async TokenControl(context) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return await axios
      .post(url + "api/auth/control")
      .then((response) => {
        if (response == null && response.data.message != "token active") {
          if (router.fullPath != "/login") {
            context.dispatch("LogOut");
          }
        } else {
          context.commit("fullname", response.data.name);
          context.commit("profile_image", response.data.profile_image);
          context.commit("auth", response.data.auths);
          if (router.fullPath == "/login") {
            setTimeout(() => {
              router.push("/dashboard");
            }, 2000);
          }
        }
      })
      .catch((e) => {
        localStorage.removeItem("token");
        context.dispatch("LogOut", e);
      });
  },
  async LogOut(context, e) {
    let data = "";
    if (JSON.stringify(e) != "{}") {
      data = JSON.parse(JSON.stringify(e)).message.substr(
        JSON.parse(JSON.stringify(e)).message.length - 3
      );
    }
    if (data == "403") {
      localStorage.removeItem("token");
      router.push("/404");
    }
    if (data == "500") {
      router.push("/404");
    }
    if (data == "401") {
      localStorage.removeItem("token");
      router.push("/login");
    }
  },
  async logout(context) {
    localStorage.removeItem("token");
    router.push("/login");
  },
  passwordReset(context, info) {
    return axios
      .post(url + "api/auth/general/password-reset", info)
      .then((response) => {
        return response.data;
      });
  },
  /**DASHBOARD ACTION */
  dashboardGet(contex) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/dashboard/get")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /**CATEGORY ACTION */
  categoryAdd(context, categoryInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/category/create", categoryInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /**CITY ACTION */
  cityList(context) {
    return axios
      .post(url + "api/auth/general/city/get")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  categoryGet(context, categoryInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/category/get", categoryInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  categoryUpdate(context, categoryInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/category/update", categoryInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  categoryRemove(context, categoryInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/category/remove ", categoryInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  categoryList(context, categoryInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/category/list ", categoryInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /** COMMENT ACTION*/
  blogCommentList(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/comment/blog/list", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  blogCommentApproved(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/comment/blog/approved", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  blogCommentRemove(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/comment/blog/remove", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  blogProfileCommentList(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/comment/profile/list", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  blogProfileCommentApproved(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/comment/profile/approved", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  blogProfileCommentRemove(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/comment/profile/remove", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /**FAQ ACTION */
  faqList(context) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/faq/list")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  faqSave(context, data) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/faq/save", data)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /**PAGE ACTION */
  pageAdd(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/page/create", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  pageGet(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/page/get", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  pageUpdate(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/page/update", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  pageRemove(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/page/remove ", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  pageList(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/page/list ", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },

  /**BLOG ACTION */
  blogAdd(context, blogInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/blog/create", blogInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  blogGet(context, blogInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/blog/get", blogInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  blogUpdate(context, blogInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/blog/update", blogInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  blogRemove(context, blogInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/blog/remove ", blogInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  blogList(context, blogInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/blog/list ", blogInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },

  /**USER ACTION */
  userAdd(context, userInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/user/create", userInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  userList(context, userInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/user/list", userInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  userRemove(context, userInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/user/remove ", userInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  userGet(context, userInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/user/get", userInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  profileGet(context, userInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/user/get/profile", userInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  profileUpdate(context, userInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/user/get/profile/update", userInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  profileImageUpload(context, image) {
    let config = {
      header: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/user/get/profile/upload", image, config)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },

  userUpdate(context, userInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/user/update", userInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /**BİLLİNG ACTION */
  billingList(context, billingInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/billing/list", billingInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  billingGet(context, billingInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/billing/get", billingInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  billingStatusUpdate(context, billingInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/billing/status/change", billingInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  billingPriceUpdate(context, billingInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/billing/price/change", billingInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  billingUploadFile(context, billingInfo) {
    let config = {
      header: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/billing/upload", billingInfo, config)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /**MEMBER ACTION */
  memberAdd(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/create", memberInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  memberEducationList(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/education/list", info)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  memberEducationGet(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/education/get", info)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  memberEducationRemove(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/education/remove", info)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  educationWaitingPayList(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/education/waiting/pay/list", info)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  educationWaitingPayApproved(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/education/waiting/pay/approved", info)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  educationWaitingPayRemove(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/education/waiting/pay/remove", info)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  memberList(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/list", memberInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  memberSave(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/save", memberInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  memberChangeBillingList(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/billing/change/list", memberInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  memberChangeBillingApproved(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/billing/change/approved", memberInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  memberChangeBillingCancel(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/billing/change/cancel", memberInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },

  memberExpertAppointments(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/appointment/list", memberInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  preAppointmentList(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/preappointment/list", memberInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  preAppointmentGet(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/preappointment/get", memberInfo)
      .then((response) => {
        if(response.data.type=='success'){
          return response.data;
        } else {
          if(response.data.message=='ERRxANF'){
            //Randevu bulunamadı
            router.push("/404");
          }
        }
        
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  preAppointmentPaymentSet(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/preappointment/payment/add", info)
      .then((response) => {
        return response.data; 
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  preAppointmentMemoSet(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/preappointment/memo/add", info)
      .then((response) => {
        return response.data; 
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  preAppointmentLogsGet(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/preappointment/logs/get", info)
      .then((response) => {
        return response.data; 
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  preAppointmentLogRemove(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/preappointment/logs/remove", info)
      .then((response) => {
        return response.data; 
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  preAppointmentPriceChange(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/preappointment/payment/change", info)
      .then((response) => {
        return response.data; 
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },

  preAppointmentChange(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/preappointment/change", info)
      .then((response) => {
        return response.data; 
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },

  preAppointmentRemove(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/preappointment/remove", info)
      .then((response) => {
        return response.data; 
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },

  /**APPOINTMENTS ACTION */
  getAppointments(context, appointmentsInfo) {
    return axios
      .post(url + "api/auth/general/profile/appointment/get", appointmentsInfo)
      .then((response) => {
        return response.data;
      });
  },
  appointmentSave(context, appointmentsInfo) {
    return axios
      .post(url + "api/auth/member/expert/appointment/save", appointmentsInfo)
      .then((response) => {
        return response.data;
      });
  },
  preAppointmentSave(context, appointmentsInfo) {
    return axios
      .post(url + "api/auth/member/expert/preappointment/save", appointmentsInfo)
      .then((response) => {
        return response.data;
      });
  },
  appointmentControl(context, info) {
    return axios
      .post(url + "api/auth/general/appointment_control", info)
      .then((response) => {
        return response.data;
      });
  },
  citysGet(context, pageInfo) {
    return axios
      .post(url + "api/auth/general/city/get", pageInfo)
      .then((response) => {
        return response.data;
      });
  },

  memberExpertEducationApproved(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/education/approved", memberInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  memberExpertApproved(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/expert/approved", memberInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  memberRemove(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/remove ", memberInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  memberGet(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/get", memberInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  memberUpdate(context, memberInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/member/update", memberInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  memberProfileUpload(context, mediaInfo) {
    let config = {
      header: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(
        url + "api/auth/member/expert/profile_image/upload",
        mediaInfo,
        config
      )
      .then((response) => {
        return response.data;
      });
  },
  mediaGalleryUpload(context, mediaInfo) {
    let config = {
      header: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(
        url + "api/auth/member/expert/media/profile_gallery_image/upload",
        mediaInfo,
        config
      )
      .then((response) => {
        return response.data;
      });
  },
  educationUpdate(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/education/update", info)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  educationCommentList(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/comment/education/list", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  educationCommentApproved(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/comment/education/approved", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  educationCommentRemove(context, pageInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/comment/education/remove", pageInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /**TAG ACTION */
  tagFinder(context, serviceInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/tags/find", serviceInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /**SERVICE ACTION */
  serviceAdd(context, serviceInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/service/create", serviceInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  serviceList(context, serviceInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/service/list", serviceInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  serviceListAll(context) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/service/list/all")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  serviceRequestList(context, serviceInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/service/request/list", serviceInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  serviceRequestRemove(context, serviceInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/service/request/remove", serviceInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  serviceRemove(context, serviceInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/service/remove", serviceInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  serviceGet(context, serviceInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/service/get", serviceInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  serviceUpdate(context, serviceInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/service/update", serviceInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },

  /**MENU ACTION */
  menuScopeList(context, menuInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/menu/scope/list", menuInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  menuContentList(context, menuInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/menu/content/list", menuInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  menuContentGet(context, menuInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/menu/content/get", menuInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  menuScopeUpdate(context, menuInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/menu/scope/create", menuInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  menuContentAdd(context, menuInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/menu/content/create", menuInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  menuContentUpdate(context, menuInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/menu/content/update", menuInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  menuScopeRemove(context, menuInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/menu/content/remove", menuInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /**ADS ACTION */
  adsCreate(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/adss/create", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  adsList(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/adss/list", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  adsRemove(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/adss/remove", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  adsGet(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/adss/get", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  adsUpdate(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/adss/update", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /**SURVEY ACTION */
  surveyCreate(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/survey/create", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  surveyList(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/survey/list", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  surveyRemove(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/survey/remove", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  surveyGet(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/survey/get", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  surveyUpdate(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/survey/update", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /**JOB ACTION */
  jobCreate(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/job/create", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  jobList(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/job/list", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  jobRemove(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/job/remove", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  jobGet(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/job/get", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  jobUpdate(context, ads) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/job/update", ads)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /**MOBİLE ACTION */
  mobileSliderCreate(context, mobile) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/mobile/slider/save", mobile)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  mobileSliderList(context, mobile) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/mobile/slider/list", mobile)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  mobileSliderRemove(context, mobile) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/mobile/slider/remove", mobile)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  mobileSliderGet(context, mobile) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/mobile/slider/get", mobile)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  mobileSliderUpdate(context, mobile) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/mobile/slider/update", mobile)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /**MEDIA ACTION */
  mediaList(context, menuInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/media/list", menuInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  mediaInfoSave(context, mediaInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/media/save", mediaInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  mediaAdsUpload(context, mediaInfo) {
    let config = {
      header: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/media/adss/upload", mediaInfo, config)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  mediaUpload(context, mediaInfo) {
    let config = {
      header: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/media/upload", mediaInfo, config)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          //localStorage.removeItem('token')
          //router.push("/login");
        }
      });
  },

  /**VİSİTOR ACTION */
  visitorList(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/visitor/list", info)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  visitorAdd(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/visitor/list/add", info)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  visitorRemove(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/visitor/list/remove", info)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  visitorUpdate(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/visitor/list/update", info)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  visitorStatusChange(context, info) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/visitor/list/change/status", info)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  /***SETTING ACTION*/
  settingGet(context, menuInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/setting/get", menuInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  settingUpdate(context, menuInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/setting/update", menuInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  smsTextGet(context, menuInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/sms-text/get", menuInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
  smsTextUpdate(context, menuInfo) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return axios
      .post(url + "api/auth/sms-text/update", menuInfo)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status==403) {
          router.push("/403");
        }
      });
  },
};
